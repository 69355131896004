import { COUNTRY_TOP_LEVEL_DOMAIN } from '../../constants';

const getMetaFrench = (topLevelDomain: string) => {
    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.bj:
            return {
                homePageTitle:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Benin | Achetez et Vendez Localement",
                homePageDesc:
                    "À la recherche d'une voiture d'occasion certifiée à Benin? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Vendre Voiture Benin, Marché Automobile, AUTO24 Benin, Voitures de Qualité, Vente Auto Benin",
                homePageTitleOg:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Benin | Achetez et Vendez Localement",
                homePageDescOg:
                    "À la recherche d'une voiture d'occasion certifiée à Benin? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Vendre Voiture Benin, Marché Automobile, AUTO24 Benin, Voitures de Qualité, Vente Auto Benin",
                buyPageTitle:
                    "Achetez des Voitures d'Occasion de Qualité à Benin | AUTO24.ci",
                buyPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Benin.",
                buyPageKeywords:
                    "Acheter Voitures Benin, Voitures d'Occasion Certifiées, AUTO24 Benin, Shopping de Voitures, Voitures Abordables Benin",
                buyPageTitleOg:
                    "Achetez des Voitures d'Occasion de Qualité à Benin | AUTO24.ci",
                buyPageDescOg:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Benin.",
                buyPageKeywordsOg:
                    "Acheter Voitures Benin, Voitures d'Occasion Certifiées, AUTO24 Benin, Shopping de Voitures, Voitures Abordables Benin",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement à Benin, Benin | AUTO24.ci",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? AUTO24.ci offre une expérience sans tracas, avec des évaluations gratuites et des ventes rapides. Vendez votre voiture d'occasion localement à Benin aujourd'hui.",
                sellPageKeywords:
                    "Vendre Voiture Benin, Vente de Voitures d'Occasion Benin, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Benin",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement à Benin | AUTO24.ci",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? AUTO24.ci offre une expérience sans tracas, avec des évaluations gratuites et des ventes rapides. Vendez votre voiture d'occasion localement à Benin aujourd'hui.",
                sellPageKeywordsOg:
                    "Vendre Voiture Benin, Vente de Voitures d'Occasion Benin, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Benin",
                aboutPageTitle:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Benin",
                aboutPageDesc:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Benin. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Benin, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Benin",
                aboutPageTitleOg:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Benin",
                aboutPageDescOg:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Benin. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Benin, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Benin",
                financePageTitle:
                    "Financez Votre Voiture d'Occasion Facilement avec AUTO24.ci à Benin",
                financePageDesc:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion à Benin. Avec AUTO24.ci , acheter une voiture n'a jamais été aussi facile.",
                financePageKeywords:
                    "Financement de Voiture, Auto Crédit, AUTO24 Benin, Options de Financement, Prêt Auto",
                financePageTitleOg:
                    "Financez Votre Voiture d'Occasion Facilement avec AUTO24.ci à Benin",
                financePageDescOg:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion à Benin. Avec AUTO24.ci , acheter une voiture n'a jamais été aussi facile.",
                financePageKeywordsOg:
                    "Financement de Voiture, Auto Crédit, AUTO24 Benin, Options de Financement, Prêt Auto",
                insurePageTitle:
                    "Assurez Votre Voiture d'Occasion de Confiance avec AUTO24.ci à Benin",
                insurePageDesc:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ci . Choisissez parmi une gamme d'options pour répondre à vos besoins.",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Benin, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    "Assurez Votre Voiture d'Occasion de Confiance avec AUTO24.ci à Benin",
                insurePageDescOg:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ci . Choisissez parmi une gamme d'options pour répondre à vos besoins.",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Benin, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    " Blog AUTO24.ci - Conseils, Astuces et Actualités sur le Marché Automobile à Benin",
                blogPageDesc:
                    "Découvrez les dernières nouvelles, conseils et astuces sur le marché automobile à Benin. Le blog AUTO24.ci est votre ressource pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywords:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Benin, Astuces Voiture",
                blogPageTitleOg:
                    " Blog AUTO24.ci - Conseils, Astuces et Actualités sur le Marché Automobile à Benin",
                blogPageDescOg:
                    "Découvrez les dernières nouvelles, conseils et astuces sur le marché automobile à Benin. Le blog AUTO24.ci est votre ressource pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywordsOg:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Benin, Astuces Voiture",
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Benin',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Benin en Côte d’Ivoire. Satisfait ou remboursé, garantie, assurance, maintenance et plus !'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            return {
                homePageTitle:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDesc:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                homePageTitleOg:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDescOg:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                buyPageTitle:
                    "Achetez des Voitures d'Occasion de Qualité au Maroc | AUTO24.ma",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywords:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                buyPageTitleOg:
                    "Achetez des Voitures d'Occasion de Qualité au Maroc | AUTO24.ma",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywordsOg:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                sellPageTitle:
                    'Vendez Votre Voiture Rapidement et Facilement au Maroc | AUTO24.ma',
                sellPageDesc:
                    'Vous souhaitez vendre votre voiture? AUTO24.ma offre une expérience sans tracas pour le faire, fournissant des évaluations gratuites et des ventes rapides dans tout le pays.',
                sellPageKeywords:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                sellPageTitleOg:
                    'Vendez Votre Voiture Rapidement et Facilement au Maroc | AUTO24.ma',
                sellPageDescOg:
                    'Vous souhaitez vendre votre voiture? AUTO24.ma offre une expérience sans tracas pour le faire, fournissant des évaluations gratuites et des ventes rapides dans tout le pays.',
                sellPageKeywordsOg:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                aboutPageTitle:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywords:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                aboutPageTitleOg:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywordsOg:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                financePageTitle:
                    "Financez Facilement Votre Voiture d'Occasion avec AUTO24.ma au Maroc",
                financePageDesc:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion avec AUTO24.ma . Simplifiez l'achat de voitures dans tout le Maroc.",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    "Financez Facilement Votre Voiture d'Occasion avec AUTO24.ma au Maroc",
                financePageDescOg:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion avec AUTO24.ma . Simplifiez l'achat de voitures dans tout le Maroc.",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurez Votre Voiture de Manière Fiable avec AUTO24.ma au Maroc',
                insurePageDesc:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ma . Choisissez parmi une gamme d'options pour répondre à vos besoins dans tout le pays.",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurez Votre Voiture de Manière Fiable avec AUTO24.ma au Maroc',
                insurePageDescOg:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ma . Choisissez parmi une gamme d'options pour répondre à vos besoins dans tout le pays.",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    'Blog AUTO24.ma - Conseils, Astuces et Actualités sur le Marché Automobile au Maroc',
                blogPageDesc:
                    "Restez à jour avec les dernières nouvelles, conseils et astuces sur le marché automobile marocain. AUTO24.ma est votre ressource incontournable pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                blogPageTitleOg:
                    'Blog AUTO24.ma - Conseils, Astuces et Actualités sur le Marché Automobile au Maroc',
                blogPageDescOg:
                    "Restez à jour avec les dernières nouvelles, conseils et astuces sur le marché automobile marocain. AUTO24.ma est votre ressource incontournable pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} DH avec AUTO24, Maroc',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Maroc. Satisfait ou remboursé, garantie, assurance, maintenance et plus !'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            return {
                homePageTitle:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire | Achetez et Vendez Localement",
                homePageDesc:
                    "À la recherche d'une voiture d'occasion certifiée à Abidjan, Côte d'Ivoire? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Abidjan, Vendre Voiture Côte d'Ivoire, Marché Automobile, AUTO24 Côte d'Ivoire, Voitures de Qualité, Vente Auto Côte d'Ivoire",
                homePageTitleOg:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire | Achetez et Vendez Localement",
                homePageDescOg:
                    "À la recherche d'une voiture d'occasion certifiée à Abidjan, Côte d'Ivoire? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Abidjan, Vendre Voiture Côte d'Ivoire, Marché Automobile, AUTO24 Côte d'Ivoire, Voitures de Qualité, Vente Auto Côte d'Ivoire",
                buyPageTitle:
                    "Achetez des Voitures d'Occasion de Qualité à Abidjan | AUTO24.ci",
                buyPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire.",
                buyPageKeywords:
                    "Acheter Voitures Abidjan, Voitures d'Occasion Certifiées, AUTO24 Côte d'Ivoire, Shopping de Voitures, Voitures Abordables Côte d'Ivoire",
                buyPageTitleOg:
                    "Achetez des Voitures d'Occasion de Qualité à Abidjan | AUTO24.ci",
                buyPageDescOg:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire.",
                buyPageKeywordsOg:
                    "Acheter Voitures Abidjan, Voitures d'Occasion Certifiées, AUTO24 Côte d'Ivoire, Shopping de Voitures, Voitures Abordables Côte d'Ivoire",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement à Abidjan, Côte d'Ivoire | AUTO24.ci",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? AUTO24.ci offre une expérience sans tracas, avec des évaluations gratuites et des ventes rapides. Vendez votre voiture d'occasion localement à Abidjan aujourd'hui.",
                sellPageKeywords:
                    "Vendre Voiture Abidjan, Vente de Voitures d'Occasion Côte d'Ivoire, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Côte d'Ivoire",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement à Abidjan, Côte d'Ivoire | AUTO24.ci",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? AUTO24.ci offre une expérience sans tracas, avec des évaluations gratuites et des ventes rapides. Vendez votre voiture d'occasion localement à Abidjan aujourd'hui.",
                sellPageKeywordsOg:
                    "Vendre Voiture Abidjan, Vente de Voitures d'Occasion Côte d'Ivoire, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Côte d'Ivoire",
                aboutPageTitle:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire",
                aboutPageDesc:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Abidjan, Côte d'Ivoire. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Côte d'Ivoire, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Abidjan",
                aboutPageTitleOg:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire",
                aboutPageDescOg:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Abidjan, Côte d'Ivoire. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Côte d'Ivoire, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Abidjan",
                financePageTitle:
                    "Financez Votre Voiture d'Occasion Facilement avec AUTO24.ci à Abidjan, Côte d'Ivoire",
                financePageDesc:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion à Abidjan. Avec AUTO24.ci , acheter une voiture n'a jamais été aussi facile.",
                financePageKeywords:
                    "Financement de Voiture, Auto Crédit, AUTO24 Côte d'Ivoire, Options de Financement, Prêt Auto",
                financePageTitleOg:
                    "Financez Votre Voiture d'Occasion Facilement avec AUTO24.ci à Abidjan, Côte d'Ivoire",
                financePageDescOg:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion à Abidjan. Avec AUTO24.ci , acheter une voiture n'a jamais été aussi facile.",
                financePageKeywordsOg:
                    "Financement de Voiture, Auto Crédit, AUTO24 Côte d'Ivoire, Options de Financement, Prêt Auto",
                insurePageTitle:
                    "Assurez Votre Voiture d'Occasion de Confiance avec AUTO24.ci à Abidjan, Côte d'Ivoire",
                insurePageDesc:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ci . Choisissez parmi une gamme d'options pour répondre à vos besoins.",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Côte d'Ivoire, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    "Assurez Votre Voiture d'Occasion de Confiance avec AUTO24.ci à Abidjan, Côte d'Ivoire",
                insurePageDescOg:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ci . Choisissez parmi une gamme d'options pour répondre à vos besoins.",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Côte d'Ivoire, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    " Blog AUTO24.ci - Conseils, Astuces et Actualités sur le Marché Automobile à Abidjan, Côte d'Ivoire",
                blogPageDesc:
                    "Découvrez les dernières nouvelles, conseils et astuces sur le marché automobile à Abidjan. Le blog AUTO24.ci est votre ressource pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywords:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Côte d'Ivoire, Astuces Voiture",
                blogPageTitleOg:
                    " Blog AUTO24.ci - Conseils, Astuces et Actualités sur le Marché Automobile à Abidjan, Côte d'Ivoire",
                blogPageDescOg:
                    "Découvrez les dernières nouvelles, conseils et astuces sur le marché automobile à Abidjan. Le blog AUTO24.ci est votre ressource pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywordsOg:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Côte d'Ivoire, Astuces Voiture",
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Abidjan, Côte d’Ivoire',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Abidjan en Côte d’Ivoire. Satisfait ou remboursé, garantie, assurance, maintenance et plus !'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            return {
                homePageTitle:
                    "AUTO24.rw - Voitures d'occasion certifiées à Kigali, Rwanda | Achetez et vendez localement",
                homePageDesc:
                    "Votre marché local à Kigali pour l'achat et la vente de voitures d'occasion certifiées. Profitez d'évaluations gratuites, de transactions rapides, et de voitures de qualité.",
                homePageKeywords:
                    "Voitures d'occasion certifiées, Acheter des voitures à Kigali, Vendre des voitures au Rwanda, AUTO24 Rwanda, Voitures de qualité à Kigali, Marché automobile au Rwanda",
                homePageTitleOg:
                    "AUTO24.rw - Voitures d'occasion certifiées à Kigali, Rwanda | Achetez et vendez localement",
                homePageDescOg:
                    "Votre marché local à Kigali pour l'achat et la vente de voitures d'occasion certifiées. Profitez d'évaluations gratuites, de transactions rapides, et de voitures de qualité.",
                homePageKeywordsOg:
                    "Voitures d'occasion certifiées, Acheter des voitures à Kigali, Vendre des voitures au Rwanda, AUTO24 Rwanda, Voitures de qualité à Kigali, Marché automobile au Rwanda",
                buyPageTitle:
                    "Achetez des voitures d'occasion de qualité certifiée à Kigali | AUTO24.rw",
                buyPageDesc:
                    "Parcourez une large gamme de voitures d'occasion certifiées sur AUTO24.rw . Simplifiez votre recherche pour la voiture idéale à Kigali.",
                buyPageKeywords:
                    "Acheter des voitures au Rwanda, Voitures d'occasion certifiées, AUTO24 Rwanda, Shopping de voiture, Voitures abordables à Kigali",
                buyPageTitleOg:
                    "Achetez des voitures d'occasion de qualité certifiée à Kigali | AUTO24.rw",
                buyPageDescOg:
                    "Parcourez une large gamme de voitures d'occasion certifiées sur AUTO24.rw . Simplifiez votre recherche pour la voiture idéale à Kigali.",
                buyPageKeywordsOg:
                    "Acheter des voitures au Rwanda, Voitures d'occasion certifiées, AUTO24 Rwanda, Shopping de voiture, Voitures abordables à Kigali",
                sellPageTitle:
                    'Vendez votre voiture rapidement à Kigali avec AUTO24.rw',
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture à Kigali? AUTO24.rw vous offre une méthode sans tracas avec des évaluations gratuites et des ventes rapides. Vendez localement dès aujourd'hui.",
                sellPageKeywords:
                    "Vendre une voiture au Rwanda, Ventes de voitures d'occasion à Kigali, Ventes de voitures rapides, AUTO24 Vendez votre voiture, Évaluation de voiture à Kigali",
                sellPageTitleOg:
                    'Vendez votre voiture rapidement à Kigali avec AUTO24.rw',
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture à Kigali? AUTO24.rw vous offre une méthode sans tracas avec des évaluations gratuites et des ventes rapides. Vendez localement dès aujourd'hui.",
                sellPageKeywordsOg:
                    "Vendre une voiture au Rwanda, Ventes de voitures d'occasion à Kigali, Ventes de voitures rapides, AUTO24 Vendez votre voiture, Évaluation de voiture à Kigali",
                aboutPageTitle:
                    "À propos d'AUTO24.rw - Votre marché de voitures d'occasion de confiance à Kigali",
                aboutPageDesc:
                    "Découvrez AUTO24.rw , le principal marché de voitures d'occasion certifiées à Kigali. Nous offrons des voitures de qualité et une expérience d'achat et de vente transparente.",
                aboutPageKeywords:
                    "À propos d'AUTO24 Rwanda, Marché de voitures d'occasion certifiées, Ventes de voitures de confiance, Concessionnaire au Rwanda",
                aboutPageTitleOg:
                    "À propos d'AUTO24.rw - Votre marché de voitures d'occasion de confiance à Kigali",
                aboutPageDescOg:
                    "Découvrez AUTO24.rw , le principal marché de voitures d'occasion certifiées à Kigali. Nous offrons des voitures de qualité et une expérience d'achat et de vente transparente.",
                aboutPageKeywordsOg:
                    "À propos d'AUTO24 Rwanda, Marché de voitures d'occasion certifiées, Ventes de voitures de confiance, Concessionnaire au Rwanda",
                financePageTitle:
                    'Financez facilement votre achat de voiture avec AUTO24.rw',
                financePageDesc:
                    "Accédez à des options de financement de voiture abordables et fiables grâce à AUTO24.rw . Conduisez votre voiture de rêve aujourd'hui !",
                financePageKeywords:
                    'Financement de voiture au Rwanda, AUTO24 Financement, Prêts de voiture abordables, Financement de voiture à Kigali',
                financePageTitleOg:
                    'Financez facilement votre achat de voiture avec AUTO24.rw',
                financePageDescOg:
                    "Accédez à des options de financement de voiture abordables et fiables grâce à AUTO24.rw . Conduisez votre voiture de rêve aujourd'hui !",
                financePageKeywordsOg:
                    'Financement de voiture au Rwanda, AUTO24 Financement, Prêts de voiture abordables, Financement de voiture à Kigali',
                insurePageTitle:
                    'Assurez votre voiture en toute sécurité avec AUTO24.rw',
                insurePageDesc:
                    "Trouvez des solutions d'assurance voiture complètes et abordables grâce à AUTO24.rw à Kigali, Rwanda.",
                insurePageKeywords:
                    'Assurance voiture au Rwanda, AUTO24 Assurance, Assurance véhicule à Kigali, Assurance abordable',
                insurePageTitleOg:
                    'Assurez votre voiture en toute sécurité avec AUTO24.rw',
                insurePageDescOg:
                    "Trouvez des solutions d'assurance voiture complètes et abordables grâce à AUTO24.rw à Kigali, Rwanda.",
                insurePageKeywordsOg:
                    'Assurance voiture au Rwanda, AUTO24 Assurance, Assurance véhicule à Kigali, Assurance abordable',
                blogPageTitle:
                    "Blog d'AUTO24.rw - Conseils d'experts et perspectives sur les voitures d'occasion au Rwanda",
                blogPageDesc:
                    "Découvrez des conseils d'experts, des guides et les dernières actualités sur le marché des voitures d'occasion à Kigali et au Rwanda.",
                blogPageKeywords:
                    "Blog d'AUTO24, Conseils de voiture au Rwanda, Actualités automobile au Rwanda, Perspectives sur les voitures d'occasion, Marché de la voiture au Rwanda",
                blogPageTitleOg:
                    "Blog d'AUTO24.rw - Conseils d'experts et perspectives sur les voitures d'occasion au Rwanda",
                blogPageDescOg:
                    "Découvrez des conseils d'experts, des guides et les dernières actualités sur le marché des voitures d'occasion à Kigali et au Rwanda.",
                blogPageKeywordsOg:
                    "Blog d'AUTO24, Conseils de voiture au Rwanda, Actualités automobile au Rwanda, Perspectives sur les voitures d'occasion, Marché de la voiture au Rwanda",
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24,Rwanda',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Rwanda. Satisfait ou remboursé, garantie, assurance, maintenance et plus !'
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            return {
                homePageTitle:
                    "Voitures d'Occasion Certifiées à Dakar, Sénégal | Achetez et Vendez Localement",
                    // "Voitures d'occasion certifiées en Senegal avec AUTO24",
                homePageDesc:
                    `Vous cherchez une voiture d'occasion certifiée à Dakar, Sénégal? AUTO24.sn est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Nous offrons des évaluations gratuites, des ventes rapides et une certification complète.`,
                    // 'Le prix de l’occasion avec le service du neuf ! Achetez des voitures d’occasion reconditionnées et certifiées par nos experts avec assurance et garantie.',
                sellPageTitle:
                    'Vendez votre voiture rapidement en Senegal - AUTO24',
                sellPageDesc:
                    'Vendez votre voiture facilement, rapidement et de façon sécurisée à Senegal. Nos inspecteurs viennent chez vous et vous donnent un prix en 30 minutes !',
                buyPageTitle:
                    'Acheter des voitures d’occasion certifiées en Senegal - AUTO24',
                buyPageDesc:
                    'Les meilleurs prix de voitures d’occasion à Senegal avec 100 points d’inspection, 5 jours satisfait ou remboursé, 6 mois de garantie et plus.',
                financePageTitle:
                    'Financer votre voiture en Senegal - AUTO24',
                financePageDesc:
                    'Découvrez les meilleures offres de financement pour nos voitures d’occasion avec nos partenaires à Senegal.',
                aboutPageTitle:
                    'AUTO24, une nouvelle façon d’acheter et vendre des voitures en Afrique',
                blogPageTitle:
                    ' L’actualité automobile en Senegal avec AUTO24',
                blogPageDesc:
                    'Conseils d’entretien, et toute l’actualité automobile en Senegal avec AUTO24.',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                insurePageTitle:
                    'Assurer votre voiture en Senegal - AUTO24',
                insurePageDesc:
                    'Découvrez les meilleures offres d’assurance pour nos voitures d’occasion avec nos partenaires à Senegal.',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Senegal',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Senegal. Satisfait ou remboursé, garantie, assurance, maintenance et plus !'
            };
        default:
            return {
                homePageTitle:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDesc:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                homePageTitleOg:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDescOg:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                buyPageTitle:
                    "Achetez des Voitures d'Occasion de Qualité au Maroc | AUTO24.ma",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywords:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                buyPageTitleOg:
                    "Achetez des Voitures d'Occasion de Qualité au Maroc | AUTO24.ma",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywordsOg:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                sellPageTitle:
                    'Vendez Votre Voiture Rapidement et Facilement au Maroc | AUTO24.ma',
                sellPageDesc:
                    'Vous souhaitez vendre votre voiture? AUTO24.ma offre une expérience sans tracas pour le faire, fournissant des évaluations gratuites et des ventes rapides dans tout le pays.',
                sellPageKeywords:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                sellPageTitleOg:
                    'Vendez Votre Voiture Rapidement et Facilement au Maroc | AUTO24.ma',
                sellPageDescOg:
                    'Vous souhaitez vendre votre voiture? AUTO24.ma offre une expérience sans tracas pour le faire, fournissant des évaluations gratuites et des ventes rapides dans tout le pays.',
                sellPageKeywordsOg:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                aboutPageTitle:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywords:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                aboutPageTitleOg:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywordsOg:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                financePageTitle:
                    "Financez Facilement Votre Voiture d'Occasion avec AUTO24.ma au Maroc",
                financePageDesc:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion avec AUTO24.ma . Simplifiez l'achat de voitures dans tout le Maroc.",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    "Financez Facilement Votre Voiture d'Occasion avec AUTO24.ma au Maroc",
                financePageDescOg:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion avec AUTO24.ma . Simplifiez l'achat de voitures dans tout le Maroc.",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurez Votre Voiture de Manière Fiable avec AUTO24.ma au Maroc',
                insurePageDesc:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ma . Choisissez parmi une gamme d'options pour répondre à vos besoins dans tout le pays.",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurez Votre Voiture de Manière Fiable avec AUTO24.ma au Maroc',
                insurePageDescOg:
                    "Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec AUTO24.ma . Choisissez parmi une gamme d'options pour répondre à vos besoins dans tout le pays.",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    'Blog AUTO24.ma - Conseils, Astuces et Actualités sur le Marché Automobile au Maroc',
                blogPageDesc:
                    "Restez à jour avec les dernières nouvelles, conseils et astuces sur le marché automobile marocain. AUTO24.ma est votre ressource incontournable pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                blogPageTitleOg:
                    'Blog AUTO24.ma - Conseils, Astuces et Actualités sur le Marché Automobile au Maroc',
                blogPageDescOg:
                    "Restez à jour avec les dernières nouvelles, conseils et astuces sur le marché automobile marocain. AUTO24.ma est votre ressource incontournable pour tout ce qui concerne les voitures d'occasion.",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} DH avec AUTO24',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}}. Satisfait ou remboursé, garantie, assurance, maintenance et plus !'
            };
    }
};

export default getMetaFrench;
