import {
    ALL_LANGUAGES,
    COUNTRY_TOP_LEVEL_DOMAIN,
    FILTERED_LANG
} from '../constants';
import commonLang from '../translation/common';
import heroLang from '../translation/hero';
import metaLang from '../translation/meta';
import routesLang from '../translation/routes';

export function getTopLevelDomain(): string {
    const topLevelDomainArr = window.location.hostname.split('auto24.');
    let topLevelDomain = '';

    if (topLevelDomainArr.includes('localhost'))
        topLevelDomain = topLevelDomainArr[0] !== 'localhost' ? topLevelDomainArr[0] : 'ma'; //For local testing
    else if(topLevelDomainArr.length == 1) // ngrok/nip.io testing
        topLevelDomain = 'ma'
    else topLevelDomain = topLevelDomainArr[topLevelDomainArr.length - 1]; //For production

    return topLevelDomain;
}

export function languagesList() {
    const topLevelDomain = getTopLevelDomain();
    let languagesList: { [key: string]: string } = {};
    FILTERED_LANG.forEach((item) => {
        if (item.includedInDomain.includes(topLevelDomain)) {
            languagesList = {
                ...languagesList,
                [item.label]: item.value
            };
        }
    });
    return languagesList;
}

export function getDefaultLang() {
    const topLevelDomain = getTopLevelDomain();
    let defaultLang = '';

    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.asterdio:
            defaultLang = ALL_LANGUAGES.french.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.bj:
            defaultLang = ALL_LANGUAGES.french.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            defaultLang = ALL_LANGUAGES.french.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            defaultLang = ALL_LANGUAGES.english.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            defaultLang = ALL_LANGUAGES.french.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            defaultLang = ALL_LANGUAGES.english.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            defaultLang = ALL_LANGUAGES.french.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.tg:
            defaultLang = ALL_LANGUAGES.french.value;
            break;
        default:
            defaultLang = ALL_LANGUAGES.english.value;
    }
    return defaultLang;
}

export const DEFAULT_LANG = getDefaultLang();

function fetchAllLangList(): any {
    let allLangList = {};

    for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES.hasOwnProperty(lang)) {
            allLangList = {
                ...allLangList,
                [ALL_LANGUAGES[lang].label]: {
                    common: commonLang[ALL_LANGUAGES[lang].value],
                    routes: routesLang[ALL_LANGUAGES[lang].value],
                    meta: metaLang[ALL_LANGUAGES[lang].value],
                    hero: heroLang[ALL_LANGUAGES[lang].value]
                }
            };
        }
    }

    return allLangList;
}

export const getCountryImage = () => {
    const topLevelDomain = getTopLevelDomain();
    let imageSrcAlt = {
        src: '',
        alt: ''
    };
    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.asterdio:
            imageSrcAlt = {
                src: '/images/flags/rwanda.webp',
                alt: 'Rwanda'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.bj:
            imageSrcAlt = {
                src: '/images/flags/benin.webp',
                alt: 'Benin'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            imageSrcAlt = {
                src: '/images/flags/cote-divoire.webp',
                alt: 'Cote-divoire'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.coke:
            imageSrcAlt = {
                src: '/images/flags/kenya.webp',
                alt: 'Kenya'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            imageSrcAlt = {
                src: '/images/flags/south-africa.webp',
                alt: 'South-africa'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            imageSrcAlt = {
                src: '/images/flags/morocco.webp',
                alt: 'Morocco'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            imageSrcAlt = {
                src: '/images/flags/rwanda.webp',
                alt: 'Rwanda'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            imageSrcAlt = {
                src: '/images/flags/senegal.webp',
                alt: 'Senegal'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.tg:
            imageSrcAlt = {
                src: '/images/flags/togo.webp',
                alt: 'Togo'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.tz:
            imageSrcAlt = {
                src: '/images/flags/tanzania.webp',
                alt: 'Tanzania'
            };
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.ug:
            imageSrcAlt = {
                src: '/images/flags/uganda.webp',
                alt: 'Uganda'
            };
            break;

        default:
            imageSrcAlt = {
                src: '/images/flags/south-africa.webp',
                alt: 'South-africa'
            };
    }
    return { ...imageSrcAlt };
};

// Object for all the languages
// Creates object similar ko constant below with same name
const LANG_LIST = fetchAllLangList();

// Object for the languages that are available for the domain
// Creates object similar ko function below with same name
export const getTranslationObj = () => {
    const langList = languagesList();
    let availableLang: any = {};
    for (const lang in langList) {
        if (langList.hasOwnProperty(lang)) {
            availableLang = {
                ...availableLang,
                [langList[lang]]: LANG_LIST[lang]
            };
        }
    }
    return availableLang;
};

export function getGoogleAnalyticsId() {
    const topLevelDomain = getTopLevelDomain();
    let gaId = '';

    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            gaId = 'G-G7J9Q0N30V';
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            gaId = 'G-E55324TXF3';
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            gaId = 'G-ZQN3YB5QZM';
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            gaId = 'G-8L01WB6523';
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            gaId = 'G-VE9VPHBXFY';
            break;

        default:
            gaId = '';
    }
    return gaId;
}
